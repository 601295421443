<template>
  <div class="w-full mb-5">
    <h1 class="text-2xl font-bold">
      {{ $t("business.settings.payout_option_title") }}
    </h1>
    <p class="mt-4">
      {{ $t("business.settings.payout_option_description") }}
    </p>
  </div>
  <div class="text-left border bg-white rounded-lg">
    <div class="px-4 py-6 flex flex-col space-y-8 text-sm">
      <label class="inline-flex items-center">
        <input type="radio" class="form-radio focus:ring-transparent" name="business.payout_option"
          v-model="business.config.payout_type" value="daily" />
        <span class="ml-2">{{ $t("business.settings.payout_auto") }}</span>
      </label>
      <label class="inline-flex items-center">
        <input type="radio" class="form-radio focus:ring-transparent" name="business.payout_option"
          v-model="business.config.payout_type" value="on_demand" />
        <span class="ml-2">{{ $t("business.settings.payout_on_demand") }}</span>
      </label>
    </div>
    <div class="px-4 py-2.5 bg-gray-50 rounded-b-lg sm:flex sm:flex-row-reverse">
      <app-button @click="save()" :showf70Icon="false" type="button" class="sm:ml-3 sm:w-auto" :loading="loading">
        {{ $t("general.save") }}
      </app-button>

      <app-button-outline type="button" class="
          px-4
          py-2
          mt-3
          w-full
          shadow-sm
          bg-white
          rounded-md
          font-medium
          inline-flex
          outline-none
          justify-center
          text-gray-700
          hover:bg-gray-50
          border border-gray-300
          sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
        " @click="resetDefault">
        {{ $t("business.settings.reset_default_button") }}
      </app-button-outline>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },
  },

  beforeCreate() {
    this.$store.dispatch("businessStore/getBusinessFromSelectedRoleBusinessId");
  },

  methods: {
    async save() {
      const businessResult = await this.$store.dispatch(
        "businessStore/statusPayout",
        {
          business_id: this.$route.params.business_id,
          payout_status: this.business.config.payout_type
        },
      );

      if (businessResult != null) {
        this.$notify(
          {
            group: "success",
            title: this.$t("message.update_successful"),
          },
          3000
        );
      }
    },

    async resetDefault() {
      this.business.config.payout_type = "daily"
      this.save()
    }
  },
};
</script>